
  import { timezones } from 'rails_data'

  import Combobox from './combobox.vue'
  import Label from './label.vue'

  export default
    props:
      modelValue: String
      label: String

    data: ->
      value: @modelValue
      timezones: timezones

    watch:
      modelValue: ->
        @value = @modelValue
        @setTimezone()

    created: ->
      @setTimezone()

    methods:
      setTimezone: ->
        @timezone = @timezones.find (tz) => tz.key == @value
      update: (e) ->
        @$emit('update:modelValue', @timezone.key)
      focus: ->
        @$refs.combobox.focus()
      searchTimezones: (query) ->
        Promise.resolve(
          @timezones.filter (tz) =>
            tz.label.toLowerCase().match(/\w+/g).some (segment) => segment.startsWith(query.toLowerCase())
        )

    components:
      Combobox: Combobox
      Label: Label

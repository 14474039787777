
  import LanguageStatesAndSelection from './language_states_and_selection.vue'
  import PersistentMenuItem from './persistent_menu_item.vue'
  import { Button, Label, Switch } from 'components/generic'

  export default
    props:
      modelValue: Object
      channelType: String

    data: ->
      menu: @modelValue
      currentLanguage: 'default'
      languageStates: {}
      languageStateTableTitle: 'Labels and URLs filled for:<br><span class="text-muted">(menus will only be generated for fully filled languages, the others will be discarded)</span>'

    computed:
      availableLanguages: ->
        Object.assign({default: 'default'}, @$root.languages)
      mayDisableComposerInput: ->
        ['WEB', 'FACEBOOK'].includes(@channelType)
      listIsFull: ->
        @menu.call_to_actions.length >= 20

    watch:
      modelValue: ->
        @menu = @modelValue
      menu:
        handler: ->
          @$emit('update:modelValue', @menu)
          @setLanguageStates()
        deep: true

    created: ->
      @setLanguageStates()

    methods:
      addMenuItem: ->
        return if @listIsFull
        @menu.call_to_actions.push(@newMenuItem())
      removeMenuItem: (index) ->
        @menu.call_to_actions.splice(index, 1)
      newMenuItem: ->
        title: {}
        type: 'postback'
        payload: ''
      setLanguageStates: ->
        states = {}
        selectors = [
          'call_to_actions.title',
          'call_to_actions.url',
        ]
        Object.keys(@availableLanguages).forEach (languageCode) =>
          states[languageCode] ||= {}
          states[languageCode][@channelType] = LanguageValidator.filledIn(
            @menu,
            languageCode,
            @channelType,
            selectors
          )
        @languageStates = states

    components:
      Button: Button
      Label: Label
      LanguageStatesAndSelection: LanguageStatesAndSelection
      PersistentMenuItem: PersistentMenuItem
      Switch: Switch

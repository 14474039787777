
  import AgentTeam from 'models/agent_team'

  import { Checkbox, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'

  export default
    props:
      modelValue: AgentTeam

    data: ->
      team: @modelValue
      teamAgents: {}

    watch:
      modelValue: ->
        @team = @modelValue
        @setTeamAgents()
      '$root.agents': ->
        @setTeamAgents()

    created: ->
      @setTeamAgents()

    methods:
      setTeamAgents: ->
        @$root.agents.forEach (agent) =>
          @teamAgents[agent.id] = @team?.agents.map((a) -> a.id).includes(agent.id)
      updateTeamAgents: ->
        @team.agents = @$root.agents.filter (agent) => @teamAgents[agent.id]
        @update()
      update: ->
        @$emit('update:modelValue', @team)

    components:
      Checkbox: Checkbox
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper

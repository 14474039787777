
  import Button from './button.vue'

  export default
    props:
      editing:
        type: Boolean
        default: null
      sending:
        type: Boolean
        default: null
      disabled:
        type: Boolean
        default: false
      editButtonLabel:
        type: String
        default: 'Edit'

    emits: ['edit', 'save', 'cancel']

    components:
      Button: Button

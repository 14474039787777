
  export default
    props:
      modelValue: String
      languages: Object
      media: Array
      languageStates:
        type: Object
        default: -> {}
      tableTitle: String

    data: ->
      currentLanguage: @modelValue
      channelTypeLogos: Globals.channelTypeLogos

    watch:
      modelValue: ->
        @currentLanguage = @modelValue
      currentLanguage: ->
        @$emit('update:modelValue', @currentLanguage)

    methods:
      languageStateLabelClass: (state) ->
        {
          none: 'label-info'
          all: 'label-success'
          some: 'label-warning'
        }[state]

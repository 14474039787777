import Agent from 'models/agent'
import Availability from 'models/availability'
import PlatformApi from 'models/platform_api'

export default class AgentTeam
  @AVAILABILITY_MODES = [
    {key: 'CALCULATED', label: 'Automatic', description: 'Calculated from agents’ availabilities.'}
    {key: 'FIXED', label: 'Fixed', description: 'Enter dedicated service hours for this team.'}
  ]

  @list: (project = Globals.project) ->
    PlatformApi.Handover.getTeams(project)
      .then (data) -> data.map (teamData) -> new AgentTeam(teamData)

  constructor: (data = {}) ->
    @name = ''
    @agents = []
    @availabilityMode = 'CALCULATED'
    @availability = new Availability(@)
    @handoverTags = []
    @checkinExplicit = false
    @update(data)

  update: (data) ->
    @id = data.id if data.id?
    @name = data.name if data.name?
    @agents = data.agents.map((data) -> new Agent(data)) if data.agents?
    @availabilityMode = data.availabilityMode if data.availabilityMode?
    @availability = new Availability(@, data.availability) if data.availability?
    @handoverTags = data.handoverTags if data.handoverTags?
    @checkinExplicit = data.checkinExplicit if data.checkinExplicit?
    return this

  save: ->
    if @id
      PlatformApi.Handover.updateAgentTeam(@)
    else
      PlatformApi.Handover.createAgentTeam(@).then (teamData) => @update(teamData)

  delete: ->
    PlatformApi.Handover.deleteAgentTeam(@)

  Object.defineProperties @prototype,
    label:
      get: -> @name
      set: (val) -> @name = val
    valid:
      get: -> !!@agents.length
    export:
      get: ->
        id: @id
        name: @name
        agents: @agents.map (agent) -> id: agent.id
        availabilityMode: @availabilityMode
        availability: @availability.export
        handoverTags: @handover_tags
        checkinExplicit: @checkinExplicit

import { createApp } from 'vue_shims'
import { createWebHistory, createRouter } from 'vue-router'

import Agent from 'models/agent'
import AgentTeam from 'models/agent_team'
import { trackRouting } from 'helpers'

import AgentTeamCreator from 'components/agent_teams/agent_team_creator.vue'
import AgentTeamEditor from 'components/agent_teams/agent_team_editor.vue'
import AgentTeamList from 'components/agent_teams/agent_team_list.vue'

initAgentTeamsApp = (element) ->
  routes = [
    name: 'index',
    path: '/',
    component: AgentTeamList,
  ,
    name: 'agentTeam',
    path: '/:teamId',
    component: AgentTeamEditor,
  ,
    name: 'newAgentTeam',
    path: '/',
    component: AgentTeamCreator,
  ].map (item) -> Object.assign(item, path: '/projects/:projectId/:stageLevel/agent_teams2' + item.path)

  router = createRouter(
    history: createWebHistory()
    routes: routes
  )
  trackRouting(router)

  createApp(
    data: ->
      elementData = JSON.parse(element.getAttribute('data'))

      agentTeams: []
      agents: []
      slackLoginUrl: elementData.slack_login_url

    computed:
      currentRoute: ->
        return {} unless @$route.name?
        @$router.options.routes.find (route) => route.name == @$route.name

    watch:
      '$route.name': -> @setBreadcrumbs()
      '$route.params.teamId': -> @setBreadcrumbs()

    created: ->
      @loadAgentTeams()
      @loadAgents()

    methods:
      loadAgentTeams: ->
        @agentTeams = await AgentTeam.list()
      loadAgents: ->
        @agents = await Agent.list()
      setBreadcrumbs: ->
        if @currentRoute.breadcrumbParent?
          parentRoute = @$router.options.routes.find (route) => route.name == @currentRoute.breadcrumbParent
          window.breadcrumbs.strip().append(
            text: parentRoute.label
            callback: => @$router.push(name: parentRoute.name)
          )
        else
          window.breadcrumbs.strip()
          window.breadcrumbs.append(text: @currentRoute.label) if @currentRoute.label?

    template: '
      <router-view/>
    '
  )
    .use(router)
    .mount(element)


export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('agent-teams-app')
      initAgentTeamsApp(element)


  import AgentTeam from 'models/agent_team'

  import AgentSelector from './agent_selector.vue'
  import AvailabilityEditor from './availability_editor.vue'
  import { BackLink, Badge, H3, Label, PageHeader, RadioPanels, Switch } from 'components/generic'

  export default
    data: ->
      team: null
      teamAgents: {}
      debounceTimeout: null
      AgentTeam: AgentTeam

    watch:
      '$root.agentTeams': ->
        @setTeam()

    created: ->
      @setTeam()

    methods:
      setTeam: ->
        @team = @$root.agentTeams.find (team) => team.id == @$route.params.teamId
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @team.save()), 1000)

    components:
      AgentSelector: AgentSelector
      AvailabilityEditor: AvailabilityEditor
      BackLink: BackLink
      Badge: Badge
      H3: H3
      Label: Label
      PageHeader: PageHeader
      RadioPanels: RadioPanels
      Switch: Switch

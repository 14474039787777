
  import Availability from 'models/availability'
  import { weekdays } from 'rails_data'

  import { Button, DatetimePicker, TimezoneSelector } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Availability

    data: ->
      availability: @modelValue
      weekdays: weekdays
      PlusIcon: PlusIcon

    methods:
      addPeriod: ->
        @availability.addPeriod()
      removePeriod: (index) ->
        @availability.removePeriod(index)
        @update()
      update: ->
        @$emit('update:modelValue', @availability)

    components:
      Button: Button
      DatetimePicker: DatetimePicker
      TimezoneSelector: TimezoneSelector
      XIcon: XIcon

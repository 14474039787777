
  import PlatformApi from 'models/platform_api.coffee'
  import { alert, confirm } from 'helpers'
  import { ActionMenu, Badge, TableCell, TableRow } from 'components/generic'
  import { ChartSquareBarIcon } from '@heroicons/vue/outline'
  import { XCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      job: Object
      index: Number

    emits: ['digest-page', 'remove']

    data: ->
      actionMenuItems: [
        {icon: XCircleIcon, label: 'Delete', method: @deleteJob, hr: true},
      ]

    computed:
      channel: ->
        @$root.channels.find((ch) => ch.uuid == @job.channelId)
      dateTime: ->
        return unless @job.dateSubmitted
        moment(@job.dateSubmitted).format(t('formats.dateTime'))
      phoneNumber: ->
        @channel?.phone_number
      filterLabels: ->
        return [] unless @job.config?.filters instanceof Array
        @job.config.filters.map (filter) =>
          contextParameter = @$root.parameterByLabel(filter.field)
          (contextParameter?.humanizedLabel || contextParameter?.label || filter.field) +
            filter.operator +
            (if filter.value? then filter.value else '')

    methods:
      deleteJob: ->
        return unless await confirm("Do you really want to delete this promotion job?")
        PlatformApi.WaTemplates.deleteJob(@job.id)
          .then => @$emit('remove', @job.id)
          .catch (error) -> await alert("Sorry, job could not be deleted.\nError message:\n" + error?.message)

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      ChartSquareBarIcon: ChartSquareBarIcon
      TableCell: TableCell
      TableRow: TableRow
      XCircleIcon: XCircleIcon

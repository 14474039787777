import PlatformApi from 'models/platform_api.coffee'
import AgentTeam from 'models/agent_team.coffee'

export default class ProjectUser
  constructor: (data) ->
    @projectId = data.projectId
    @stageLevel = data.stageLevel
    @id = data.id
    @name = data.name
    @organizationId = data.organization_id
    @role = data.role || {}
    @teams = (data.teams || []).map (teamData) -> new AgentTeam(teamData)
    @isNew = data.isNew

  clone: ->
    new ProjectUser(@export)

  save: ->
    method = if @isNew then 'addProjectUser' else 'updateProjectUser'
    PlatformApi.Webapp[method](@projectId, @stageLevel, @)
      .then => @isNew = false

  remove: ->
    PlatformApi.Webapp.removeProjectUser(@projectId, @stageLevel, @id)

  Object.defineProperties @prototype,
    export:
      get: ->
        projectId: @projectId
        stageLevel: @stageLevel
        id: @id
        name: @name
        organization_id: @organizationId
        role: JSON.parse(JSON.stringify(@role))
        teams: @teams.map (team) -> team.export
        isNew: @isNew

<template>
  <div class="typing-animation">
    <span class="dot-list">
      <span class="dot dot-1"></span>
      <span class="dot dot-2"></span>
      <span class="dot dot-3"></span>
    </span>
  </div>
</template>

<style lang="scss">
  .typing-animation {
    @keyframes jumpingDot {
      0% {
        top: 16px;
      }
      30% {
        top: 5px;
      }
      60% {
        top: 16px;
      }
      100% {
        top: 16px;
      }
    }
    padding-top: 10px;
    .dot-list {
      background-color: #e7e7e7;
      border-radius: 8px;
      display: inline-block;
      position: relative;
      width: 60px;
      height: 32px;
      .dot {
        position: absolute;
        background-color: #C9C9C9;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 16px;
        animation: jumpingDot 2s ease-in-out infinite;
        &.dot-1 {
          left: 10px;
          animation-delay: 0s;
        }
        &.dot-2 {
          left: 25px;
          animation-delay: 0.33s;
        }
        &.dot-3 {
          left: 40px;
          animation-delay: 0.66s;
        }
      }
    }
  }
</style>


  import { Button, Input, Label, Listbox } from 'components/generic'
  import { TrashIcon } from '@heroicons/vue/outline'

  export default
    props:
      item: Object
      index: Number
      currentLanguage: String

    data: ->
      selectedIntent: null
      availableIntents: [{name: 'select'}].concat @$root.intents.map (intent) -> {id: intent, name: intent}

    created: ->
      @selectedIntent = @availableIntents.find((intent) => intent.id == @item.payload) || @availableIntents[0]

    watch:
      'item.type': ->
        switch @item.type
          when 'web_url'
            @item.url = {}
            delete @item.payload
          when 'payload'
            @item.payload = ''
            delete @item.url
      selectedIntent: ->
        @item.payload = @selectedIntent.id

    methods:
      remove: ->
        @$emit('remove', @index)

    components:
      Button: Button
      Input: Input
      Label: Label
      Listbox: Listbox
      TrashIcon: TrashIcon

import PlatformApi from 'models/platform_api'

export default class HandoverConfig
  @load: (project = Globals.project) ->
    PlatformApi.Handover.getConfig()
      .then (data) -> new HandoverConfig(data)

  constructor: (data = {}) ->
    @responseThresholds = {}
    @update(data)

  update: (data) ->
    @responseThresholds = data.responseThresholds if data.responseThresholds

  save: =>
    PlatformApi.Handover.updateConfig(@export)

  Object.defineProperties @prototype,
    responseThresholdYellow:
      get: -> @responseThresholds.yellow / 1000
      set: (val) -> @responseThresholds.yellow  = val * 1000
    responseThresholdRed:
      get: -> @responseThresholds.red / 1000
      set: (val) -> @responseThresholds.red  = val * 1000
    export:
      get: ->
        responseThresholds: @responseThresholds


  import AgentTeam from 'models/agent_team'

  import AgentSelector from './agent_selector.vue'
  import { BackLink, H3, Input, PageHeader, Panel } from 'components/generic'

  export default
    data: ->
      team: new AgentTeam()
      disabled: true

    methods:
      cancel: ->
        @$router.push(name: 'index')
      save: ->
        @team.save()
          .then =>
            @$root.agentTeams.push(@team)
            @$router.push(name: 'agentTeam', params: {teamId: @team.id})

    components:
      AgentSelector: AgentSelector
      BackLink: BackLink
      H3: H3
      Input: Input
      PageHeader: PageHeader
      Panel: Panel

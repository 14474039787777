import AvailabilityPeriod from 'models/availability_period'
import PlatformApi from 'models/platform_api'

export default class Agent
  DEFAULT_TIMEZONE = 'Europe/Berlin'

  @ATTRIBUTES = [
    'firstName'
    'lastName'
    'languages'
    'availability'
    'teamIds'
    'status'
    'online'
    'name'
    'checkedIn'
    'requiresCheckIn'
  ]

  @list: (project = Globals.project) ->
    PlatformApi.Handover.getAgents(project)
      .then (data) -> data.map (agentData) -> new Agent(agentData)

  @load: (project = Globals.project) ->
    PlatformApi.Handover.getAgent(project)
      .then (data) -> new Agent(data)

  constructor: (data) ->
    @id = data.id
    @update(data)

  update: (data) ->
    for attribute in @constructor.ATTRIBUTES
      if attribute == 'availability'
        av = data.availability || {}
        av.timezone ||= DEFAULT_TIMEZONE
        av.serviceHours = (av.serviceHours || []).filter((sh) -> sh?).map (sh) -> new AvailabilityPeriod(sh)
        @availability = av
      else
        @[attribute] = data[attribute] if data[attribute]?
    return this

  clone: ->
    new Agent(ObjectProcessor.clone(@))

  Object.defineProperties @prototype,
    fullName:
      get: ->
        @firstName + ' ' + @lastName
    initials:
      get: ->
        ((@firstName[0] || '') + (@lastName[0] || '')).toUpperCase()
    initialsFromList:
      get: ->
        try
          ((@name.split(' ')[0][0] || '') + (@name.split(' ')[1][0] || '')).toUpperCase()
        catch
          ''

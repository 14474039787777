
  import Conversation from 'models/conversation'
  import { alert } from 'helpers'

  import AgentAssignment from './agent_assignment.vue'
  import AnswerTemplateSelector from './answer_template_selector.vue'
  import UploadButton from './upload_button.vue'
  import VMdEditor from 'v_md_editor'
  import { Button } from 'components/generic'
  import { LogoutIcon, PaperAirplaneIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      conversation: Conversation

    data: ->
      message: ''
      smallView: false
      sending: false
      notifiedOfTyping: false
      selectedFile: null

    computed:
      isMobileSafari: ->
        navigator.vendor &&
          navigator.vendor.indexOf('Apple') > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf('CriOS') == -1 &&
          navigator.userAgent.indexOf('FxiOS') == -1 &&
          navigator.userAgent.match(/iP(ad|hone)/i)

    watch:
      '$root.selectedAnswerTemplate': ->
        return if !@$root.selectedAnswerTemplate?
        @message = @$root.selectedAnswerTemplate.parsedMessageText
          .map (part) =>
            if part.type == 'expression' then @conversation.context[part.text] else part.text
          .join('')
        @$nextTick => @$refs.input.focus()

    mounted: ->
      @$refs.input.$el?.addEventListener 'keyup', (e) =>
        @notifyOfTyping()
        @send() if e.which == 13 && e.ctrlKey
      @adaptToWidth()
      window.addEventListener('resize', @adaptToWidth)

    unmounted: ->
      window.removeEventListener('resize', @adaptToWidth)

    methods:
      send: ->
        return if @sending
        return if @message.length == 0
        @sending = true
        @$root.api.sendMessage(@conversation, @message, @$root.selectedAnswerTemplate?.id)
          .then =>
            @$emit('reset-answer-template')
            @message = ''
          .catch => await alert('Sorry, sending message failed!')
          .finally =>
            @sending = false
            @$refs.input.focus()
      stopHandover: ->
        @sending = true
        @$root.api.stopHandover(@conversation)
          .then => @conversation.handoverSession.timeClosed = new Date().toISOString()
          .catch => await alert('Sorry, handover mode could not be terminated!')
          .finally => @sending = false
      setTemplate: (template) ->
        @$emit('set-answer-template', template)
      adaptToWidth: ->
        @smallView = @$el.getBoundingClientRect().width < 900
      setFile: (file) ->
        @selectedFile = file
      sendFile: ->
        return if @sending
        @sending = true
        @$root.api.sendFile(@conversation, @selectedFile)
          .then =>
            @selectedFile = null
            @$nextTick =>
              @$refs.input.focus()
          .finally => @sending = false
      notifyOfTyping: ->
        return if @notifiedOfTyping
        @$root.api.notifyOfTyping(@conversation)
        @notifiedOfTyping = true
        setTimeout(
          => @notifiedOfTyping = false
        , 5000
        )

    components:
      AgentAssignment: AgentAssignment
      AnswerTemplateSelector: AnswerTemplateSelector
      Button: Button
      LogoutIcon: LogoutIcon
      PaperAirplaneIcon: PaperAirplaneIcon
      UploadButton: UploadButton
      VMdEditor: VMdEditor
      XIcon: XIcon


  import PanelBottom from './panel_bottom.vue'

  export default
    props:
      editing:
        type: Boolean
        default: null
      sending:
        type: Boolean
        default: null
      disabled:
        type: Boolean
        default: false
      editButtonLabel:
        type: String
        default: 'Edit'
      bgColor:
        type: String
        default: 'white'
      fullHeightInner:
        type: Boolean
        default: false

    emits: ['edit', 'save', 'cancel']

    computed:
      bgColorClass: -> "bg-#{@bgColor}"

    components:
      PanelBottom: PanelBottom

export default class Availability
  constructor: (@host, data = {}) ->
    @timezone = 'Europe/Berlin'
    @serviceHours = []
    @update(data)

  update: (data) ->
    @timezone = data.timezone if data.timezone?
    @serviceHours = data.serviceHours if data.serviceHours?

  addPeriod: ->
    @serviceHours.push(
      days: []
      start: '08:00'
      end: '16:00'
    )

  removePeriod: (index) ->
    @serviceHours.splice(index, 1)

  Object.defineProperties @prototype,
    export:
      get: ->
        timezone: @timezone
        serviceHours: @serviceHours

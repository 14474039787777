
  import Label from './label.vue'
  import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
  import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

  export default
    props:
      label: String
      collection: Array
      modelValue: Object
      helpText: String
      errorText: String
      idAttribute:
        type: String
        default: 'id'
      nameAttribute:
        type: String
        default: 'name'
      nameFunction:
        type: Function
        default: null
      disabled:
        type: Boolean
        default: false
      placeholder:
        type: String
        default: null
      size:
        type: String
        default: 'md'
      hGroupPosition:
        type: String
        default: 'none'
      align:
        type: String
        default: 'left'

    data: ->
      selectedItem: @modelValue

    computed:
      supplementedCollection: ->
        if @collection.map((item) => item[@idAttribute]).includes(@modelValue[@idAttribute])
          @collection
        else
          [@modelValue].concat(@collection)
      borderClasses: ->
        additionalClasses = switch @hGroupPosition
          when 'first' then ['border-l border-t border-b rounded-l-md']
          when 'mid' then ['border-l border-t border-b']
          when 'last' then ['border rounded-r-md']
          else ['border rounded-md']
        ['border-gray-300 shadow-sm focus:ring-1 focus:ring-MercuryBlue-500 focus:border-MercuryBlue-500'].concat(additionalClasses)
      paddingClasses: ->
        switch @size
          when 'sm'
            'pl-3 pr-10 py-1'
          else
            'pl-3 pr-10 py-2'
      buttonClasses: ->
        ['block bg-white relative w-full text-left cursor-pointer focus:outline-none sm:text-sm disabled:bg-gray-50']
          .concat(@borderClasses).concat(@paddingClasses)
      buttonLabel: ->
        @itemLabel(@selectedItem) || @placeholder
      buttonLabelClass: ->
        if !@selectedItem[@idAttribute]
          'text-gray-400'
        else
          ''

    watch:
      modelValue: ->
        @selectedItem = @modelValue

    methods:
      itemSelected: (item) ->
        @$emit('update:modelValue', item)
        @$emit('selected', item)
      itemLabel: (item) ->
        return '' if !item?
        if @nameFunction
          @nameFunction(item)
        else
          item[@nameAttribute]
      itemDisabled: (item) ->
        !item[@idAttribute] || item.disabled
      isSeparator: (item) ->
        !item?[@idAttribute] && @itemLabel(item) == '-----'
      isTitle: (item) ->
        !item?[@idAttribute] && !!@itemLabel(item).match(/^# (.+) #$/)
      title: (item) ->
        return false if !@isTitle(item)
        match = @itemLabel(item).match(/^# (.+) #$/)[1]

    components:
      Label: Label,
      Listbox: Listbox,
      ListboxButton: ListboxButton,
      ListboxOption: ListboxOption,
      ListboxOptions: ListboxOptions,
      CheckIcon: CheckIcon,
      SelectorIcon: SelectorIcon,
